import { mapActions } from 'vuex'
import  { myMixin } from '../../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    showFilter: false,
    payloadFilter: null,
    model: {},
    show: false,
    showForm: false,
    showFormItem: false,
    showGenerate: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    itemSelected: {
        id: 0,
    },
    headers: [
        { text: 'Professor', value: 'teacher.name'},
        { text: 'Termo', value: 'term.name'},
        { text: 'Status', value: 'situation', align: 'center'},
        { text: 'Ações', value: 'actions'},
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
      
    },
    methods: {
      ...mapActions('term', ['ActionIndexTermSignatures', 'ActionDestroyTermSignature']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showGenerate = false;
        this.showForm = false;
        let payload = this.params;

        delete payload.filter_relations;
        delete payload.find_columns;

        if(this.payloadFilter){
            
            if(this.payloadFilter.filter_relations){
                payload.filter_relations = this.payloadFilter.filter_relations;
            }

            if(this.payloadFilter.find_columns){
                payload.find_columns = this.payloadFilter.find_columns;
            }
        }

        payload.with = 'teacher,term,status,option';

        this.ActionIndexTermSignatures(payload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDestroyTermSignature({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        },

        selectItem(item){
           this.itemSelected = item;
           this.showFormItem = true;
        },

        filter(payload){
            this.payloadFilter = payload;
            this.showFilter = false;
            this.getData();
        },

  },

}
