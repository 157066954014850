var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"amber darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showGenerate = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-flash-outline")])],1)]}}])},[_c('span',[_vm._v(" Gerar Termos ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(60)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v(" Baixar relatório ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Nova Assinatura ")])])]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('sys-term-filter',{attrs:{"show":_vm.showFilter},on:{"update:show":function($event){_vm.showFilter=$event},"filter":function($event){return _vm.filter($event)}}}),_c('sys-term-generate',{attrs:{"show":_vm.showGenerate},on:{"update:show":function($event){_vm.showGenerate=$event},"onCompleted":function($event){return _vm.getData()}}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading,"find":"","showAppend":""},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.payloadFilter ? 'primary' : 'grey',"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showFilter = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"btn":""}},[_vm._v(_vm._s(_vm.payloadFilter ? 'mdi-filter-menu-outline' : 'mdi-filter-outline'))])],1)]}}])},[_c('span',[_vm._v(" Demais Filtros ")])])]},proxy:true},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.name)+" "),(item.term_signature_status_id != 1)?_c('small',[_c('br'),(item.term.is_required)?_c('span',[(item.agree)?_c('span',[_vm._v(_vm._s(item.term.label))]):_vm._e()]):_c('span',[(item.option)?_c('span',[_vm._v(_vm._s(item.option.label))]):_vm._e()])]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(item.term_signature_status_id != 1)?_c('emc-table-icon-download-pdf',{attrs:{"toolTip":"Download do Termo","type":"teacher-term-signature","item":item,"disabled":item.term_signature_status == 1 || !item.url_document}}):_vm._e(),_c('emc-table-icon-edit',{attrs:{"tool-tip":"Visualizar Termo","color":"purple","icon":"mdi-text-box-search-outline"},on:{"click":function($event){return _vm.selectItem(item)}}})]}}])}),_c('sys-register-term-signature',{attrs:{"model":_vm.model,"show":_vm.showForm,"icon":_vm.$route.meta.icon},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('sys-register-term-signature-form',{attrs:{"show":_vm.showFormItem,"view":true,"signature-id":_vm.itemSelected.id,"icon":_vm.$route.meta.icon},on:{"update:show":function($event){_vm.showFormItem=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }